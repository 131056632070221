import { FirestorePost } from "@/interfaces";
import { Post } from "@/interfaces/Post";
import { PostComment } from "@/interfaces/PostComment";
import firebase from "./firebase";
const db = firebase.firestore();
export class FirebaseApiClient {
  async getPosts(
    limit: number = 10,
    lastDate?: Date
  ): Promise<FirestorePost[]> {
    let query = db.collection("posts").orderBy("date", "desc");
    if (lastDate) {
      query = query.startAfter(lastDate);
    }
    const data = await query
      .limit(limit)
      .get()
      .then((querySnapshot) => {
        return querySnapshot.docs.map((doc) => doc.data() as FirestorePost);
      });
    return data;
  }

  async searchPosts(
    limit: number = 10,
    name: string,
    page: number = 1
  ): Promise<Post[]> {
    const data = await fetch(
      `${
        process.env.CLOUD_FUNCTIONS_URL
      }/api/search?limit=${limit}&name=${encodeURI(name)}&page=${page}`
    )
      .then((res) => res.json())
      .catch((_) => {
        return { objects: [] };
      });
    const posts = data.objects.map((doc: any) => new Post(doc));
    return posts;
  }

  async allPosts() {
    return await db
      .collection("posts")
      .orderBy("date", "desc")
      .get()
      .then((querySnapshot) =>
        querySnapshot.docs.map((doc) => doc.data() as FirestorePost)
      );
  }

  async categoryPosts(slug: string, limit: number = 10, lastDate?: Date) {
    let query = db
      .collection("posts")
      .where("category_slugs", "array-contains", encodeURI(slug).toLowerCase())
      .orderBy("date", "desc");
    if (lastDate) {
      query = query.startAfter(lastDate);
    }
    const data = await query
      .limit(limit)
      .get()
      .then((querySnapshot) => {
        return querySnapshot.docs.map((doc) => doc.data() as FirestorePost);
      });
    return data;
  }

  async tagPosts(slug: string, limit: number = 10, lastDate?: Date) {
    let query = db
      .collection("posts")
      .where("tag_slugs", "array-contains", encodeURI(slug).toLowerCase())
      .orderBy("date", "desc");
    if (lastDate) {
      query = query.startAfter(lastDate);
    }
    const data = await query
      .limit(limit)
      .get()
      .then((querySnapshot) => {
        return querySnapshot.docs.map((doc) => doc.data() as FirestorePost);
      });
    return data;
  }

  async tagsIncludePosts(slugs: string[], postId: number) {
    if (slugs.length <= 0) return [];
    const limit: number = 6;
    const query = db
      .collection("posts")
      .where("tag_slugs", "array-contains-any", slugs)
      .orderBy("date", "desc");
    const data = await query
      .limit(limit)
      .get()
      .then((querySnapshot) => {
        return querySnapshot.docs.map((doc) => doc.data() as FirestorePost);
      });
    return data.filter((p) => p.id !== postId).filter((_, i) => i < 5);
  }

  async periodPosts(
    limit: number,
    startDate: Date,
    endDate: Date,
    lastDate: Date | null
  ) {
    let query = db
      .collection("posts")
      .orderBy("date", "desc")
      .endAt(firebase.firestore.Timestamp.fromDate(startDate));
    query = lastDate
      ? query.startAfter(firebase.firestore.Timestamp.fromDate(lastDate))
      : query.startAfter(firebase.firestore.Timestamp.fromDate(endDate));
    const data = await query
      .limit(limit)
      .get()
      .then((querySnapshot) => {
        return querySnapshot.docs.map((doc) => doc.data() as FirestorePost);
      });
    return data;
  }

  async post(id: string) {
    const data = await db
      .collection("posts")
      .doc(id)
      .get()
      .then((doc) => doc.data());
    return data as FirestorePost;
  }

  async comments(postID: string): Promise<PostComment[]> {
    const data = await fetch(
      `${process.env.CLOUD_FUNCTIONS_URL}/api/comments?post_id=${postID}`
    ).then((res) => {
      return res.json();
    });
    const comments = data.objects.map(
      (comment: any) => new PostComment(comment)
    );
    // const comments = await db
    //   .collection('comments')
    //   .where('comment_post_ID', '==', `${postID}`)
    //   .where('comment_approved', '==', '1')
    //   .get()
    //   .then(querySnapshot => {
    //     return querySnapshot.docs.map(doc => new PostComment(doc.data()))
    //   })
    return comments;
  }

  async page(slug: string) {
    const data = await db
      .collection("pages")
      .doc(slug)
      .get()
      .then((doc) => doc.data());
    return data as FirestorePost;
  }

  async categories() {
    const query = db.collection("categories").orderBy("name");
    const data = await query.get().then((querySnapshot) => {
      return querySnapshot.docs.map((doc) => doc.data());
    });
    return data;
  }

  async tags() {
    const query = db.collection("tags").orderBy("count", "desc").limit(20);
    const data = await query.get().then((querySnapshot) => {
      return querySnapshot.docs.map((doc) => doc.data());
    });
    return data;
  }

  async mainCategories() {
    const data = await db
      .collection("categories")
      .orderBy("name")
      .startAt("*")
      .endAt("*" + "\uF8FF")
      .get()
      .then((querySnapshot) => {
        return querySnapshot.docs.map((doc) => doc.data());
      });
    return data;
  }

  async archives() {
    const data = await db
      .collection("archives")
      .get()
      .then((querySnapshot) => {
        return querySnapshot.docs.map((doc) => doc.data());
      });
    return data;
  }

  async updateLike(userId: string, id: number, like: boolean) {
    const updates = {
      [`/like/liked/${id}/${userId}`]: like ? 1 : 0,
    };
    await firebase.database().ref().update(updates);
  }

  async notifyToken(userId: string, token: string) {
    await firebase
      .database()
      .ref(`/notify/${userId}/${token}`)
      .once("value")
      .then(function (snapshot) {
        const val = snapshot.val();
        return val;
      });
  }

  async updateNotifyToken(token: string, allow: boolean) {
    const updates = {
      [`/notify/${token}`]: allow,
    };
    await firebase.database().ref().update(updates);
  }

  // async updateLike(id: Number, like: Boolean) {
  //   if (!firebase.functions) {
  //     require('firebase/functions')
  //   }
  //   const functions = firebase.functions()
  //   const updateLike = functions.httpsCallable('updateLike')
  //   await updateLike({ id, like })
  //     .then(function(result) {
  //       // Read result of the Cloud Function.
  //       console.log(result)
  //       return result
  //     })
  //     .catch(function(error) {
  //       console.error(error)
  //     })
  // }
}
