import { useRouter } from "next/router";
import { useEffect, useState } from "react";

type Props = {
  search: (str: string) => void;
};

const SearchForm = (props: Props) => {
  const [keyword, setKeyword] = useState("");
  const { query } = useRouter();
  useEffect(() => {
    if (typeof query.s === "string") setKeyword(query.s);
  }, [query.s]);
  return (
    <form
      id="searchform"
      method="get"
      className={"my-search field has-addons navbar-item"}
      onSubmit={(ev) => {
        ev.preventDefault();
        props.search(keyword);
      }}
    >
      <div className="control is-expanded">
        <input
          id="s"
          value={keyword}
          onChange={(ev) => setKeyword(ev.target.value)}
          className="input is-small is-rounded is-outlined"
          name="s"
          type="text"
          placeholder="キーワードを入力"
        />
      </div>
      <div className="control">
        <label className="button is-info is-small is-rounded search-button">
          <input className="is-hidden" type="submit" value="" />
          <i className="material-icons mdi-search is-size-6"></i>
        </label>
      </div>
      <style jsx>{`
        .my-search {
          margin-bottom: 0;
          @media screen and (max-width: 768px) {
            flex-grow: 1;
          }
          @media screen and (min-width: 769px) {
            margin-left: auto;
          }
        }
        .is-rounded {
          border-radius: 6px;
        }
        input.input::placeholder {
          color: #999;
        }
      `}</style>
    </form>
  );
};

export default SearchForm;
