import dayjs from "@/plugins/dayjs";
import { ConfigType } from "dayjs";
export const getDate = (date?: ConfigType) => {
  return date ? dayjs(date).local().format("YYYY-MM-DD") : null;
};

export const getDateTime = (date?: ConfigType) => {
  return date ? dayjs(date).local().format("YYYY.MM.DD HH:mm") : null;
};

export const getDateRest = (date?: ConfigType) => {
  return date ? dayjs(date).local().format("YYYY-MM-DDTHH:mm:ss") : null;
};

export const getYYMM = (date?: ConfigType) => {
  return date ? dayjs(date).local().format("YY/MM") : null;
};

export const getMMDD = (date?: ConfigType) => {
  return date ? dayjs(date).local().format("MM/DD") : null;
};

export const formatTimestamp = (seconds: number) => dayjs(seconds * 1000);

export const dateFromNow = (date?: ConfigType) => dayjs(date).local().fromNow();

export const getCurrentMoment = () => dayjs().add(0, "month");
