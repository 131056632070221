import { useClient } from "@/hooks/useClient";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

type Props = {
  vertical?: boolean;
  visible?: boolean;
};

const ShareButtons = ({ vertical = false, visible = false }: Props) => {
  const [url, setUrl] = useState("");
  const { asPath } = useRouter();
  useEffect(() => {
    setUrl(`${process.env.appUrl}${asPath}`);
  }, []);
  const { isClient } = useClient();
  const onClickTwitter = () => {
    window.open(
      encodeURI(
        decodeURI(`https://twitter.com/share?text=${document.title}&url=${url}`)
      ),
      "snswindow",
      "width=550, height=450, personalbar=0, toolbar=0, scrollbars=1"
    );
    return false;
  };
  const onClickFacebook = () => {
    window.open(
      encodeURI(
        decodeURI(`https://www.facebook.com/sharer/sharer.php?u=${url}`)
      ),
      "snswindow",
      "width=550, height=450, personalbar=0, toolbar=0, scrollbars=1"
    );
  };
  return (
    <div className={`buttons column is-narrow ${vertical ? "vertical" : ""}`}>
      {isClient && visible && (
        <>
          <a
            className="button is-info is-rounded is-positive-circled twitter"
            data-related="chiffon0903"
            onClick={onClickTwitter}
            rel="noopener"
          >
            <div className="icon-area">
              <Image width={24} height={24} src="/images/sns/twitter.svg" />
            </div>
          </a>
          <a
            className="button is-info is-rounded is-positive-circled fb"
            href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
            onClick={onClickFacebook}
            rel="noopener"
          >
            <div className="icon-area">
              <Image width={24} height={24} src="/images/sns/facebook.svg" />
            </div>
          </a>
          <a
            className="button is-info is-rounded is-positive-circled line"
            href={`http://line.me/R/msg/text/?${url}`}
            rel="noopener"
            target="_blank"
          >
            <div className="icon-area">
              <Image width={24} height={24} src="/images/sns/line.svg" />
            </div>
          </a>
          <a
            className="button is-info is-rounded is-positive-circled pocket"
            href={`http://getpocket.com/edit?url=${url}&title=${document.title}`}
            rel="noopener"
            target="_blank"
          >
            <div className="icon-area">
              <Image width={24} height={24} src="/images/sns/pocket.svg" />
            </div>
          </a>
          <a
            className="button is-info is-rounded is-positive-circled hatena"
            href={`http://b.hatena.ne.jp/entry/${url}`}
            data-hatena-bookmark-title="${title}"
            data-hatena-bookmark-lang="ja"
            title="このエントリーをはてなブックマークに追加"
            rel="noopener"
            target="_blank"
          >
            <div className="icon-area">
              <Image width={24} height={24} src="/images/sns/hatena.svg" />
            </div>
          </a>
        </>
      )}
      <style jsx>{`
        .buttons {
          font-size: 0;
          .button {
            width: 2.25em !important;
            margin-top: 0;
            margin-bottom: 0;
            background-repeat: no-repeat;
            background-size: 60% auto;
            background-position: center;
            border: none;
            &::after {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background: transparent;
              transition: all 0.3s ease;
              border-radius: 50%;
            }
            .icon-area {
              width: 24px;
              height: 24px;
              flex-shrink: 0;
            }
            &:hover {
              &::after {
                background: rgba(#fff, 0.4);
              }
            }
            &.twitter {
              background-color: #1da1f2;
              &:focus:not(:active) {
                box-shadow: 0 0 0 0.125em rgba(#1da1f2, 0.25);
              }
            }
            &.fb {
              background-color: #3b5998;
              &:focus:not(:active) {
                box-shadow: 0 0 0 0.125em rgba(#3b5998, 0.25);
              }
            }
            &.line {
              background-color: #00c300;
              &:focus:not(:active) {
                box-shadow: 0 0 0 0.125em rgba(#00c300, 0.25);
              }
            }
            &.pocket {
              background-color: #ef3f56;
              &:focus:not(:active) {
                box-shadow: 0 0 0 0.125em rgba(#ef3f56, 0.25);
              }
            }
            &.hatena {
              background-color: #00a4de;
              &:focus:not(:active) {
                box-shadow: 0 0 0 0.125em rgba(#00a4de, 0.25);
              }
            }
            &.sarahah {
              background: #10bbb3;
            }
          }
          &.vertical {
            display: flex;
            flex-direction: column-reverse;
            position: absolute;
            bottom: 40px;
            transition: all 0.3s ease;
            .button {
              transition: all 0.3s ease;
              margin: 0;
            }
            &.visible {
              transform: translateY(-8px);
              .button {
                @for $i from 1 to 6 {
                  &:nth-of-type(#{$i}) {
                    transform: translateY(-8px * ($i - 1));
                  }
                }
              }
            }
            &:not(.visible) {
              transform: translateY(40px);
              opacity: 0;
              .button {
                @for $i from 1 to 6 {
                  &:nth-of-type(#{$i}) {
                    transform: translateY(40px * ($i - 1));
                  }
                }
              }
            }
          }
        }
      `}</style>
    </div>
  );
};

export default ShareButtons;
