import { FirestorePost } from "./FirestoreTypes";
import { Taxonomy } from "./Taxonomy";
import firebase from "@/utils/api/firebase";
import { formatTimestamp } from "@/utils/dateutil";
import dayjs from "@/plugins/dayjs";
import { Dayjs } from "dayjs";
const escapeHtml = (entity: string) => {
  return entity
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&#039;/g, "'")
    .replace(/(&quot;|&ldquo;|&rdquo;)/g, '"');
};

const getDate = (date: Dayjs | firebase.firestore.Timestamp | any): Date => {
  if (typeof date.toDate === "function") {
    return date.toDate();
  } else if (date._seconds) {
    return formatTimestamp(date._seconds).toDate();
  } else {
    return dayjs(date).toDate();
  }
};

export const serializePost = (post: Post) => {
  const serealizeDate = (date: Date) => {
    return dayjs(date).toString();
  };
  return {
    ...post,
    date: serealizeDate(post.date),
    modified: serealizeDate(post.modified),
    nextPost: post.nextPost && {
      ...post.nextPost,
      date: serealizeDate(post.nextPost.date),
    },
    prevPost: post.prevPost && {
      ...post.prevPost,
      date: serealizeDate(post.prevPost.date),
    },
  };
};

export const deserializePost = (post: any): Post => {
  const deserializeDate = (date: string) => {
    return dayjs(date).toDate();
  };
  return {
    ...post,
    date: deserializeDate(post.date),
    modified: deserializeDate(post.modified),
    nextPost: post.nextPost && {
      ...post.nextPost,
      date: deserializeDate(post.nextPost.date),
    },
    prevPost: post.prevPost && {
      ...post.prevPost,
      date: deserializeDate(post.prevPost.date),
    },
  };
};

type Thumbnail = {
  full: string | undefined;
  thumbnail: string | undefined;
  large: string | undefined;
  medium: string | undefined;
};

class PostLink {
  id: string;
  title: string;
  date: Date;
  constructor(json: any) {
    this.id = json.id;
    this.title = escapeHtml(json.title);
    this.date = getDate(json.date);
  }
}

const getExcerpt = (json: FirestorePost) => {
  return json.content?.text.slice(0, 140) || "";
};

export class Post {
  id: number;
  author: number;
  slug: string;
  title: string;
  content: string;
  excerpt: string;
  date: Date;
  modified: Date;
  // taxonomy
  categories: Taxonomy[];
  categorySlugs: string[];
  categoryName: string;
  categorySlug: string;
  tags: Taxonomy[];
  tagSlugs: string[] | null;
  // meta
  postType: string;
  postStatus: string;
  pingStatus: string;
  commentStatus: "open" | "closed";
  commentCount: number;
  likeCount: number;
  session: number | null;
  rank: number | null;
  // pager
  prevPost: PostLink | null;
  nextPost: PostLink | null;
  thumbnail: Thumbnail;
  resizedThumbnail?: string;
  constructor(json: FirestorePost) {
    this.id = json.id;
    this.author = json.author;
    this.slug = json.slug;
    this.title = escapeHtml(json.title);
    this.content = json.content.rendered.replace(/&#8217;|&#8242;/g, "'");
    this.excerpt = escapeHtml(getExcerpt(json));
    this.date = getDate(json.date);
    this.modified = getDate(json.modified);

    // taxonomy
    this.categories = json.categories ?? [];
    this.categorySlugs = json.category_slugs ?? [];
    this.categoryName =
      json.categories && json.categories.length ? json.categories[0].name : "";
    this.categorySlug =
      json.categories && json.categories.length ? json.categories[0].slug : "";
    this.tags = json.tags ?? [];
    this.tagSlugs = json.tag_slugs ?? [];
    // meta
    this.postType = json.post_type ?? null;
    this.postStatus = json.post_status ?? null;
    this.pingStatus = json.ping_status ?? null;
    this.commentStatus = json.comment_status ?? null;
    this.commentCount = json.comment_count || 0;
    this.likeCount = json.like_count || 0;
    this.session = null;
    this.rank = null;
    // pager
    this.prevPost = json.postnavi_prev
      ? new PostLink(json.postnavi_prev)
      : null;
    this.nextPost = json.postnavi_next
      ? new PostLink(json.postnavi_next)
      : null;
    this.thumbnail = json.thumbnail_images || {
      full: "",
      large: "",
      medium: "",
      thumbnail: "",
    };
    this.resizedThumbnail =
      this.thumbnail.thumbnail &&
      this.thumbnail.thumbnail.includes(
        `${process.env.wpUrl}/wp-content/uploads/2`
      ) &&
      !this.thumbnail.thumbnail.includes("_thumb-")
        ? this.thumbnail.thumbnail.replace(
            /(|_thumb|-128x128|-256x256).(jpg|png|gif)/gi,
            "-128x128.$2"
          )
        : this.thumbnail.thumbnail;
  }
}
