import { CFApiClient } from "@/utils/api/CFApiClient";
import lsm from "@/utils/lsm";
import firebase from "@/utils/api/firebase";
import { useEffect, useState } from "react";

const useNotification = (api: CFApiClient) => {
  const [notifyHover, setNotifyHover] = useState(false);
  const [notifyEnabled, setNotifyEnabled] = useState(false);
  const putNotifyToken = (token: string) => {
    api.updateNotifyToken(token, true);
  };
  const deleteNotifyToken = (token: string) => {
    api.updateNotifyToken(token, false);
  };
  const unregisterNotify = () => {
    const token = lsm.getFirebaseNotifyToken();
    if (!token) return;
    deleteNotifyToken(token);
    setNotifyEnabled(false);
    lsm.setFirebaseNotifyEnabled(false);
  };
  const blockNotify = () => {
    const confirm = window.confirm("プッシュ通知の購読を解除しますか？");
    if (!confirm) return;
    unregisterNotify();
    setNotifyHover(false);
  };
  const allowNotify = () => {
    if (!('Notification' in window)) return;
    const messaging = firebase.messaging();
    Notification.requestPermission()
      .then(() => {
        console.log("have permission!");
        return messaging.getToken();
      })
      .then((token) => {
        putNotifyToken(token);
        setNotifyEnabled(true);
        lsm.setFirebaseNotifyToken(token);
        lsm.setFirebaseNotifyEnabled(true);
        setNotifyHover(false);
      })
      .catch((err) => {
        console.log("error occured.", err);
        window.alert(
          `プッシュ通知がブロックされているようです。ブラウザの設定またはアドレスバーの鍵アイコンから、${process.env.appUrl} からの通知を許可する設定を行ってください。`
        );
      });
  };
  useEffect(() => {
    if (!('Notification' in window)) return;
    if (Notification.permission === "granted") {
      setNotifyEnabled(lsm.getFirebaseNotifyEnabled());
      const messaging = firebase.messaging();
      messaging.onMessage((payload) => {
        console.log("Message received. ", payload);
      });
    } else {
      unregisterNotify();
    }
  }, []);
  return {
    notifyHover,
    setNotifyHover,
    notifyEnabled,
    setNotifyEnabled,
    putNotifyToken,
    deleteNotifyToken,
    unregisterNotify,
    blockNotify,
    allowNotify,
  };
};

export default useNotification;
