import { event } from "@/plugins/analytics";
import { useApiClient } from "./useApiClient";

import { atom, useAtom } from "jotai";
import useCurrentUserJotai from "@/store/useCurrentUserJotai";

const isLikedAtom = atom(false);
const isLoadingLikeAtom = atom(false);
const likeCountAtom = atom(0);

const useLikeJotai = () => {
  const api = useApiClient();
  const [isLiked, setIsLiked] = useAtom(isLikedAtom);
  const [isLoadingLike, setIsLoadingLike] = useAtom(isLoadingLikeAtom);
  const [likeCount, setLikeCount] = useAtom(likeCountAtom);
  const clickLikeBtn = async (id: number) => {
    const like = !isLiked;
    setIsLoadingLike(true);
    await updateLike({ id, like });
    setIsLoadingLike(false);
  };

  const { currentUser } = useCurrentUserJotai();

  const updateLike = async ({ id, like }: { id: number; like: boolean }) => {
    if (!currentUser) return;
    setIsLiked(like);
    event({
      category: "Post",
      action: like ? "like" : "dislike",
    });
    await api.updateLike(currentUser?.uid, id, like);
  };

  return {
    isLiked,
    setIsLiked,
    isLoadingLike,
    setIsLoadingLike,
    clickLikeBtn,
    likeCount,
    setLikeCount,
  };
};

export default useLikeJotai;
