import React, { useState } from "react";
import Link from "next/link";
import { useMediaQuery } from "react-responsive";
import SearchForm from "./SearchForm";
import { useClient } from "@/hooks/useClient";
import { useRouter } from "next/dist/client/router";

type Props = {
  isMenuActive: boolean;
  setIsMenuActive: (val: boolean) => void;
};
const MyHeader = ({ isMenuActive, setIsMenuActive }: Props) => {
  const [isSPSearchVisible, setIsSpSearchVisible] = useState(false);
  const isSm = useMediaQuery({ query: "(max-width: 768px)", screen: true });
  const router = useRouter();
  const search = (text: string) => {
    router.push(text ? `/?s=${encodeURI(text)}` : "/");
  };
  const { isClient } = useClient();

  return (
    <>
      <header
        className="header navbar is-fixed-top is-primary"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            {!isSPSearchVisible && (
              <Link href="/">
                <a className="navbar-item is-size-4 has-text-weight-bold">
                  Our Story's Diary
                </a>
              </Link>
            )}
            {isClient && (isSPSearchVisible || !isSm) && (
              <SearchForm search={search} />
            )}
            <>
              <a
                className={`navbar-item spsearch${
                  isClient && !isSm ? " is-hidden" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setIsSpSearchVisible(!isSPSearchVisible);
                }}
              >
                <i
                  className={`material-icons ${
                    !isSPSearchVisible ? "mdi-search" : "mdi-close"
                  }`}
                ></i>
              </a>
              <a
                role="button"
                className={`navbar-burger burger${
                  isMenuActive ? " is-active" : ""
                }${
                  isClient && (!isSm || isSPSearchVisible) ? " is-hidden" : ""
                }`}
                aria-label="menu"
                aria-expanded="false"
                onClick={() => {
                  setIsMenuActive(!isMenuActive);
                }}
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            </>
          </div>
        </div>
      </header>
      <style jsx>{`
        .header {
          box-shadow: 0 2px 3px rgba(#0a0a0a, 0.1), 0 0 0 1px rgba(#0a0a0a, 0.1);
          .navbar-brand {
            width: 100%;
          }
        }
        .spsearch {
          margin-left: auto;
          &-close {
            transition: none;
          }
        }
        .navbar-burger {
          margin-left: 0;
        }
      `}</style>
    </>
  );
};

export default MyHeader;
