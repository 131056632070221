import { atom, useAtom } from "jotai";
import firebase from "@/utils/api/firebase";
const currentUserAtom = atom<firebase.User | null>(null);

const useCurrentUserJotai = () => {
  const [currentUser, setCurrentUser] = useAtom(currentUserAtom);
  return {
    currentUser,
    setCurrentUser,
  };
};

export default useCurrentUserJotai;
