import React, { ReactNode, useEffect, useMemo, useState } from "react";
import MyHeader from "./layouts/MyHeader";
import dynamic from "next/dynamic";
const MySidebar = dynamic(import("./layouts/MySidebar"));
import { useSidebarJotai, SidebarJotai } from "@/hooks/useSidebarJotai";
import { useMediaQuery } from "react-responsive";
import MyFooter from "./layouts/MyFooter";
import firebase from "@/utils/api/firebase";
import { useClient } from "@/hooks/useClient";
import useCurrentUserJotai from "@/store/useCurrentUserJotai";
import { useRouter } from "next/router";

type Props = {
  children?: ReactNode;
  sidebarJotai: SidebarJotai | null;
};

const Layout = ({ children, sidebarJotai }: Props) => {
  const { setSidebarJotai } = useSidebarJotai();
  useEffect(() => {
    if (sidebarJotai) setSidebarJotai(sidebarJotai);
  }, [sidebarJotai]);
  const { setCurrentUser } = useCurrentUserJotai();
  const { pathname, query } = useRouter();
  const postId = useMemo(
    () => (pathname === "/archives/[id]" ? Number(query?.id) : null),
    [pathname, query]
  );
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((data) => {
      if (data) {
        setCurrentUser(data);
        unsubscribe();
      } else {
        firebase
          .auth()
          .signInAnonymously()
          .catch((error) => {
            console.error(error);
          });
      }
    });
    return unsubscribe;
  }, []);
  const isSm = useMediaQuery({ query: "(max-width: 768px)", screen: true });

  const [isMenuActive, setIsMenuActive] = useState(false);
  const { isClient } = useClient();
  return (
    <>
      <main className="main">
        <MyHeader
          isMenuActive={isMenuActive}
          setIsMenuActive={setIsMenuActive}
        />
        <div className="container">
          <section className="section articles columns">
            <div className="column is-8">{children}</div>
            <div
              className={`column is-4 sp-menu ${
                isMenuActive ? "isActive" : ""
              }`}
            >
              {isClient && (!isSm || isMenuActive) && (
                <MySidebar isVisible={!isSm || isMenuActive} />
              )}
            </div>
          </section>
        </div>
        <MyFooter postId={postId} />
      </main>
      <style jsx>{`
        .main {
          display: block;
          overflow: hidden;
        }
        .articles {
          padding-top: 1.5rem;
        }
        .article-single {
          @media screen and (min-width: 769px) {
            display: block !important;
          }
        }
        .postarea {
          margin: 0 auto;
        }
        .sp-menu {
          @media screen and (max-width: 768px) {
            position: fixed;
            background: #fff;
            z-index: 999;
            top: 52px;
            left: 0;
            right: 0;
            width: 100%;
            height: calc(100% - 52px);
            overflow-y: scroll;
            transition: all 0.3s ease;
            &:not(.isActive) {
              opacity: 0;
              transform: translateX(30%);
              pointer-events: none;
            }
          }
        }
      `}</style>
    </>
  );
};

export default Layout;
