import { Archive, Taxonomy } from "@/interfaces";
import { Post } from "@/interfaces/Post";
import { CFApiClient } from "@/utils/api/CFApiClient";
import { atom, useAtom } from "jotai";

const recentPostsAtom = atom([] as Post[]);
const popularPostsAtom = atom([] as Post[]);
const archivesAtom = atom([] as Archive[]);
const categoriesAtom = atom([] as Taxonomy[]);
const tagsAtom = atom([] as Taxonomy[]);

export type SidebarJotai = {
  archives: Archive[];
  categories: Taxonomy[];
  tags: Taxonomy[];
};

export const useSidebarJotai = () => {
  const [popularPosts, setPopularPosts] = useAtom(popularPostsAtom);
  const [recentPosts, setRecentPosts] = useAtom(recentPostsAtom);
  const [archives, setArchives] = useAtom(archivesAtom);
  const [categories, setCategories] = useAtom(categoriesAtom);
  const [tags, setTags] = useAtom(tagsAtom);
  const setSidebarJotai = (data: SidebarJotai) => {
    if (recentPosts.length > 0) return;
    setArchives(data.archives);
    setCategories([...data.categories].sort((a, b) => b.count - a.count));
    setTags([...data.tags].sort((a, b) => b.count - a.count));
  };
  return {
    recentPosts,
    archives,
    categories,
    tags,
    setSidebarJotai,
    popularPosts,
    setPopularPosts,
    setRecentPosts,
  };
};

export const fetchSidebarJotai = async (api: CFApiClient) => {
  const archives = await api.archives();
  const categories = await api.mainCategories();
  const tags = await api.tags();
  return { archives, categories, tags };
};
