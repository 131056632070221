export default class lsm {
  // static setLike(id: number) {
  //   localStorage.setItem(`is-liked-post-${id}`, 'TRUE')
  // }

  // static setUnlike(id: number) {
  //   localStorage.setItem(`is-liked-post-${id}`, 'FALSE')
  // }

  // static getLike(id: number) {
  //   const like = localStorage.getItem(`is-liked-post-${id}`)
  //   return like ? like.toLowerCase() === 'true' : false
  // }

  static setFirebaseNotifyToken(token: string) {
    localStorage.setItem(`firebaseNotifyToken`, token)
  }

  static setFirebaseNotifyEnabled(flg: boolean) {
    localStorage.setItem(`firebaseNotifyEnabled`, `${flg}`)
  }

  static getFirebaseNotifyToken() {
    return localStorage.getItem(`firebaseNotifyToken`)
  }

  static getFirebaseNotifyEnabled() {
    return localStorage.getItem(`firebaseNotifyEnabled`) === 'true'
  }
}
