import { useApiClient } from "@/hooks/useApiClient";
import { useClient } from "@/hooks/useClient";
import useLikeJotai from "@/hooks/useLikeJotai";
import useNotification from "@/hooks/useNotification";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import ShareButtons from "../atoms/ShareButtons";

type Props = {
  postId: number | null;
};

const MyFooter = ({ postId }: Props) => {
  const [isIos, setIsIos] = useState(true);
  const [sharebuttonVisible, setSharebuttonVisible] = useState(false);
  useEffect(() => {
    const ua = navigator.userAgent?.toLowerCase();
    setIsIos(
      !ua || (/ipod|ipad|iphone|macintosh/.test(ua) && "ontouchend" in document)
    );
  }, []);

  const api = useApiClient();

  const {
    notifyHover,
    setNotifyHover,
    notifyEnabled,
    blockNotify,
    allowNotify,
  } = useNotification(api);
  const { isLiked, isLoadingLike, clickLikeBtn } = useLikeJotai();
  const isSm = useMediaQuery({ query: "(max-width: 768px)", screen: true });
  const { isClient } = useClient();
  const share = () => {
    const nav: Navigator = navigator;
    const title = document.title;
    const url = location.href;

    if (!!nav?.share) {
      nav
        .share({
          // title: this.title,
          text: title,
          url,
        })
        .then(() => {
          // シェアしたら実行される
          console.log("Successful share");
        })
        .catch((error) => {
          // シェアせず終了した場合もここに入ってくる。
          console.log("Error sharing", error);
        });
    } else {
      setSharebuttonVisible(!sharebuttonVisible);
    }
  };
  return (
    <footer>
      <div className="navbar is-fixed-bottom is-mobile columns is-marginless">
        <div className="navbar-start">
          {isSm && isClient ? (
            <>
              <div className="buttons">
                <a
                  className="button is-rounded is-positive-circled button__share"
                  onClick={share}
                >
                  <i
                    className={`material-icons ${
                      sharebuttonVisible ? "mdi-close" : "mdi-share"
                    }`}
                  ></i>
                </a>
              </div>
              <ShareButtons visible={sharebuttonVisible} vertical={true} />
            </>
          ) : (
            <ShareButtons visible={true} />
          )}
        </div>
        <div className="navbar-end">
          <div className="buttons is-right">
            {!!postId ? (
              <a
                className={`button button__like is-rounded is-positive-circled is-paddingless${
                  isLiked ? " isLiked" : ""
                }${isLoadingLike ? " is-loading" : ""}`}
                onClick={() => {
                  clickLikeBtn(postId);
                }}
              >
                <i
                  className={`material-icons ${
                    isLiked ? "mdi-favorite" : "mdi-favorite_border"
                  }`}
                ></i>
              </a>
            ) : (
              !isIos && (
                <a
                  className={
                    "button button-notify is-rounded is-positive-circled is-paddingless " +
                    (notifyHover ? "is-hovered " : "") +
                    ((notifyEnabled && !notifyHover) ||
                    (!notifyEnabled && notifyHover)
                      ? "button-notify-active"
                      : "")
                  }
                  onClick={() =>
                    notifyEnabled ? blockNotify() : allowNotify()
                  }
                  onMouseOver={() => setNotifyHover(true)}
                  onMouseLeave={() => setNotifyHover(false)}
                >
                  <i
                    className={`material-icons ${
                      (notifyEnabled && notifyHover) ||
                      (!notifyEnabled && !notifyHover)
                        ? "mdi-notifications_off"
                        : "mdi-notifications"
                    }`}
                  ></i>
                </a>
              )
            )}

            <a
              id="go_to_top"
              href="#"
              className="button is-rounded is-positive-circled is-paddingless is-primary"
            >
              <i className="material-icons mdi-keyboard_arrow_up"></i>
            </a>
          </div>
        </div>
      </div>
      <style jsx>{`
        .navbar {
          min-height: auto;
          background: none;
          .navbar-end {
            flex-grow: 1;
          }
          .buttons {
            margin: 0;
            padding: 0.5rem 1rem;
            .button {
              margin: {
                top: 0;
                bottom: 0;
              }
              width: 40px;
              height: 40px;
              &__share {
                z-index: 2;
              }
              &__like {
                color: #ccc;
                &:hover,
                &.isLiked {
                  color: #ea3f60;
                }
              }
            }
          }
          .button {
            &-notify {
              color: #999;
            }
            &-notify-active {
              color: #f26522;
            }
          }
        }
      `}</style>
    </footer>
  );
};
export default MyFooter;
