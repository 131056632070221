import { Archive, FirestorePost, Taxonomy } from "@/interfaces";
import { Post } from "@/interfaces/Post";
import { FirebaseApiClient } from "./FirebaseApiClient";

export class CFApiClient extends FirebaseApiClient {
  async mainCategories(): Promise<Taxonomy[]> {
    const data = await fetch(
      `${process.env.CLOUD_FUNCTIONS_URL}/api/main_categories/`
    ).then((res) => res.json());
    return data.objects;
  }

  async tags(): Promise<Taxonomy[]> {
    const data = await fetch(
      `${process.env.CLOUD_FUNCTIONS_URL}/api/tags/`
    ).then((res) => res.json());
    return (data.objects as []).filter((_, index) => index <= 20);
  }

  async recentPosts(): Promise<FirestorePost[]> {
    const data = await fetch(
      `${process.env.CLOUD_FUNCTIONS_URL}/api/recent_posts/`
    ).then((res) => res.json());
    return data.objects;
  }

  async popularPosts(): Promise<Post[]> {
    const data = await fetch(
      `${process.env.CLOUD_FUNCTIONS_URL}/api/popular_posts/`
    ).then((res) => res.json());
    return data.objects.map((p: any) => new Post(p));
  }

  async archives(): Promise<Archive[]> {
    const data = await fetch(
      `${process.env.CLOUD_FUNCTIONS_URL}/api/archives/`
    ).then((res) => res.json());
    return data.objects;
  }
}
