export class PostComment {
  id: number;
  postID: number;
  author: any;
  authorName: string;
  authorUrl: any;
  authorAvatar: string;
  date: Date;
  content: any;
  type: any;
  parent: any;
  userID: any;
  replies: PostComment[];
  constructor(json: any) {
    this.id = json.id;
    this.postID = Number(json.comment_post_ID) || json.post;
    this.author = json.comment_author || json.author;
    this.authorName = json.comment_author_name || json.author_name;
    this.authorUrl = json.comment_author_url || json.author_url;
    this.authorAvatar = json.author_avatar_urls
      ? json.author_avatar_urls["48"]
      : "/no_image_user.png";
    this.date = json.comment_date ? json.comment_date.toDate() : json.date;
    this.content = json.comment_content || json.content.rendered;
    this.type = json.comment_type;
    this.parent = Number(json.comment_parent) || json.parent;
    this.userID = json.user_id || json.author;
    this.replies = json.replies || [];
  }
}
